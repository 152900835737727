import React from 'react';
import './assets/styles/App.css';
import ReactErrorResolver from './components/ReactErrorHandler';
import { Router } from 'react-router-dom';
import Routes from './routes';
import HistoryHandler from './services/HistoryHandler';

function App() {
  return (
    <div className='App'>
      <ReactErrorResolver>
        <Router history={HistoryHandler}>
          <Routes />
        </Router>
      </ReactErrorResolver>
    </div>
  );
}

export default App;
