import '../assets/styles/ReactLoader.css';

function ReactLoader() {
  return (
    <div className='loader'>
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default {
  ReactLoader,
};
