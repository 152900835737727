import * as React from 'react';
import { Component, ErrorInfo, PropsWithChildren } from 'react';
import { ThemedButton, ThemedOrangeText, ThemedText } from './Components';

interface IState {
  hasError: boolean;
  error?: string;
  showError: boolean;
}

export default class ReactErrorResolver extends Component<PropsWithChildren<void>,
  IState> {
  state = { hasError: false, showError: false } as IState;

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error: error.message });
    console.error(error);
    console.log(info);
  }

  render() {
    const { hasError } = this.state;
    return hasError ? (
      <div className={'py-5'}>
        <ThemedText className={'text-xl'}>Something went wrong while rendering this page.</ThemedText>
        <div className={'h-1'}></div>
        <ThemedButton onClick={() => window.location.reload()}>
          Reload
        </ThemedButton>
        <ThemedButton
          clearButtonClassName={true}
          buttonClassName={'relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-pink-200 focus:ring-pink-800'}
          onClick={() => this.setState({ showError: !this.state.showError })}
        >
          {this.state.showError ? 'Hide' : 'Show'} Error
        </ThemedButton>
        {this.state.showError && (
          <>
            <div className={'h-1'}></div>
            <ThemedOrangeText>Error code:</ThemedOrangeText>
            <div className={'h-1'}></div>
            <div className={'border-gray-500 rounded border p-2 m-2'}>
              <code className={'text-white'}>{this.state.error}</code>
            </div>
          </>
        )}
      </div>
    ) : (
      this.props.children
    );
  }
}
