import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactLoader from './components/ReactLoader';
import HistoryHandler from './services/HistoryHandler';

const Main = React.lazy(() => import('./routes/Main'));

export default function Routes() {
  function showFallback() {
    return <ReactLoader.ReactLoader />;
  }

  return (
    <Suspense fallback={showFallback()}>
      <Switch>
        <Route exact path={'/'} component={Main} />
        <Route path={'*'} render={() => {
          HistoryHandler.replace('/');
          return null;
        }} />
      </Switch>
    </Suspense>
  );
}
