import { ReactNode } from 'react';

export function ThemedText({
                             children,
                             className,
                             clearClassName = false,
                             onClick,
                             href,
                           }: {
  children: ReactNode,
  className?: string,
  clearClassName?: boolean,
  onClick?: () => void,
  href?: string,
}) {
  return (
    <>
      {href ? (
        <a
          href={href}
          target='_blank'
          className={`${onClick ? 'cursor-pointer ' : ''}` + (clearClassName ? className : `inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500${className ? ` ${className}` : ''}`)}>
          {children}
        </a>
      ) : (
        <div
          onClick={onClick}
          className={`${onClick ? 'cursor-pointer ' : ''}` + (clearClassName ? className : `inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500${className ? ` ${className}` : ''}`)}>
          {children}
        </div>
      )}
    </>
  );
}

export function ThemedOrangeText({
                                   children,
                                   onClick,
                                   href,
                                 }: {
  children: ReactNode,
  onClick?: () => void,
  href?: string,
}) {
  return (
    <ThemedText clearClassName={true}
                onClick={onClick}
                href={href}
                className={'inline text-transparent bg-clip-text bg-gradient-to-tl from-pink-500 to-orange-400'}>
      {children}
    </ThemedText>
  );
}

export function Button({
                         children,
                         className,
                         onClick,
                         clearClassName = false,
                         href,
                       }: {
  children: ReactNode,
  className?: string,
  onClick?: () => void,
  clearClassName?: boolean,
  href?: string,
}) {
  return (
    <>
      {href ? (
        <a
          href={href}
          target='_blank'
          className={clearClassName ? className : `relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg text-white group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 focus:ring-purple-800 transition ease-in-out delay-150 hover:scale-[1.1]${className ? ` ${className}` : ''}`}
        >
          {children}
        </a>
      ) : (

        <button
          disabled={onClick === undefined}
          onClick={onClick}
          className={clearClassName ? className : `relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg text-white group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 focus:ring-purple-800 transition ease-in-out delay-150 hover:scale-[1.1]${className ? ` ${className}` : ''}`}
        >
          {children}
        </button>
      )}
    </>
  );
}

export function ThemedButton({
                               children,
                               className,
                               buttonClassName,
                               onClick,
                               clearClassName = false,
                               clearButtonClassName = false,
                               href,
                             }: {
                               children: ReactNode,
                               className?: string,
                               buttonClassName?: string,
                               onClick?: () => void
                               clearClassName?: boolean,
                               clearButtonClassName?: boolean,
                               href?: string,
                             },
) {
  return (
    <Button
      href={href}
      onClick={onClick}
      className={buttonClassName}
      clearClassName={clearButtonClassName}
    >
      <ThemedText
        clearClassName={true}
        className={clearClassName ? className : `relative px-5 py-2.5 transition-all ease-in duration-75 bg-white bg-gray-900 rounded-md group-hover:bg-opacity-0${className ? ` ${className}` : ''}`}>
        {children}
      </ThemedText>
    </Button>
  );
}

export function GitHubButton({
                               input = 'Github',
                               onClick,
                               href,
                             }: {
  input?: string,
  onClick?: () => void,
  href?: string,
}) {
  return (
    <>
      {href ? (
        <a
          href={href}
          target='_blank'
          className='transition ease-in-out delay-150 hover:scale-[1.1] text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/25 mr-2 mb-2'>
          <svg className='w-4 h-4 mr-2 -ml-1' aria-hidden='true' focusable='false' data-prefix='fab' data-icon='github'
               role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512'>
            <path fill='currentColor'
                  d='M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z'></path>
          </svg>
          {input}
        </a>
      ) : (
        <button type='button'
                onClick={onClick}
                className='transition ease-in-out delay-150 hover:scale-[1.1] text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/25 mr-2 mb-2'>
          <svg className='w-4 h-4 mr-2 -ml-1' aria-hidden='true' focusable='false' data-prefix='fab' data-icon='github'
               role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512'>
            <path fill='currentColor'
                  d='M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z'></path>
          </svg>
          {input}
        </button>
      )}
    </>
  );
}

export function TwitterButton({
                                input = 'Twitter',
                                onClick,
                                href,
                              }: {
  input?: string,
  onClick?: () => void,
  href?: string,
}) {
  return (
    <>
      {href ? (
        <a
          href={href}
          target='_blank'
          className='transition ease-in-out delay-150 hover:scale-[1.1] text-white bg-[#1DA1F2] hover:bg-[#1DA1F2]/90 focus:ring-4 focus:outline-none focus:ring-[#1DA1F2]/50 font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/25 mr-2 mb-2'>
          <svg className='w-4 h-4 mr-2 -ml-1' aria-hidden='true' focusable='false' data-prefix='fab' data-icon='twitter'
               role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path fill='currentColor'
                  d='M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z'></path>
          </svg>
          {input}
        </a>
      ) : (
        <button type='button'
                onClick={onClick}
                className='transition ease-in-out delay-150 hover:scale-[1.1] text-white bg-[#1DA1F2] hover:bg-[#1DA1F2]/90 focus:ring-4 focus:outline-none focus:ring-[#1DA1F2]/50 font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/25 mr-2 mb-2'>
          <svg className='w-4 h-4 mr-2 -ml-1' aria-hidden='true' focusable='false' data-prefix='fab' data-icon='twitter'
               role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path fill='currentColor'
                  d='M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z'></path>
          </svg>
          {input}
        </button>
      )}
    </>
  );
}

export default function LinkedinButton({
                                         input = 'LinkedIn',
                                         onClick,
                                         href,
                                       }: {
  input?: string,
  onClick?: () => void,
  href?: string,
}) {
  return (
    <>
      {href ? (
        <a
          href={href}
          target='_blank'
          className='transition ease-in-out delay-150 hover:scale-[1.1] text-white bg-[#0077B5] hover:bg-[#0077B5]/90 focus:ring-4 focus:outline-none focus:ring-[#0077B5]/50 font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/25 mr-2 mb-2'>
          <svg className='w-4 h-4 mr-2 -ml-1 text-white-500 fill-current' aria-hidden='true' focusable='false'
               data-prefix='fab' data-icon='linkedin'
               role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
            <path
              d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
            ></path>
          </svg>
          {input}
        </a>
      ) : (
        <button type='button'
                onClick={onClick}
                className='transition ease-in-out delay-150 hover:scale-[1.1] text-white bg-[#0077B5] hover:bg-[#0077B5]/90 focus:ring-4 focus:outline-none focus:ring-[#0077B5]/50 font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/25 mr-2 mb-2'>
          <svg className='w-4 h-4 mr-2 -ml-1 text-white-500 fill-current' aria-hidden='true' focusable='false'
               data-prefix='fab' data-icon='linkedin'
               role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
            <path
              d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
            ></path>
          </svg>
          {input}
        </button>
      )}
    </>
  );

}
